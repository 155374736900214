<template>
  <a-row :gutter="25" v-if="eventForm.generalInfo.type != 'other'">
    <div class="card w-100">
      <a-col :xxl="7" :lg="10" :md="12" :xs="23" style="padding-left: 0" class="box-event">
        <h3 class="form-font title">Sede</h3>
      </a-col>
      <!--INICIO::FORMULARIO SEDES-->
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form name="sDash_validation-form" ref="formRef" :model="formState" :rules="rules" :layout="dataDOM.layout">
            <a-row :gutter="30" v-if="dataDOM.showGeneralForm || !dataDOM.sedes.length">
              <a-col :md="24" :xs="24">
                <a-form-item ref="type" name="type" label="Tipo de sede *" class="title-normal">
                  <a-select
                    size="large"
                    name="type"
                    class="sDash_fullwidth-select title-normal sede-text"
                    v-model:value="formState.type"
                    v-on:change="handleChangeSede($event)"
                    :disabled="dataDOM.isFormOnEdit"
                  >
                    <a-select-option class="title-normal sede-text" value="">Seleccione una opción</a-select-option>
                    <a-select-option
                      v-for="typeSede in dataDOM.typeSedes"
                      class="title-normal sede-text"
                      :key="typeSede"
                      :value="typeSede.value"
                    >
                      {{ typeSede.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" v-if="dataDOM.selectSede">
              <a-col :md="11" :xs="24">
                <a-form-item ref="sede" name="sede" label="Selecciona una sede *" class="title-normal">
                  <a-select
                    size="large"
                    name="sede"
                    class="sDash_fullwidth-select title-normal sede-text"
                    v-model:value="formState.sede_id"
                    v-on:change="handleChangeSedeSelect($event)"
                    show-search
                    :options='options'
                    optionFilterProp='label'
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-if='!dataDOM.isFormOnEdit' :md="1" :xs="24" class='mb-2'>
                <div class='mt-4'>O</div>
              </a-col>
              <a-col v-if='!dataDOM.isFormOnEdit' :md="11" :xs="24" class='mb-2'>
                <button class="btn-outlined-add btn-not-border mt-4" v-on:click="handleAddNewSede">
                  <img :src="require(`@/static/img_events/SEJ_Eventos_agregar.svg`)" alt="logo" width="17" />
                  <span class="title-normal ml-1"> Agregar nueva sede </span>
                </button>
              </a-col>

            </a-row>
            <a-row :gutter="30" v-if="dataDOM.showFormVirtual">
              <a-col :md="12" :xs="24">
                <a-form-item
                  ref="virtual_platform"
                  name="virtual_platform"
                  label="Plataforma digital donde se llevará el evento *"
                  class="title-normal"
                >
                  <a-select
                    size="large"
                    name="virtual_platform"
                    class="sDash_fullwidth-select title-normal sede-text"
                    v-model:value="formState.virtual_platform"
                  >
                    <a-select-option class="title-normal sede-text" value="">Seleccione una opción</a-select-option>
                    <a-select-option class="title-normal sede-text" key="zoom" value="zoom">
                      Zoom
                    </a-select-option>
                    <a-select-option class="title-normal sede-text" key="meet" value="meet">
                      Meet
                    </a-select-option>
                    <a-select-option class="title-normal sede-text" key="other" value="other">
                      Otro
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" v-if="formState.virtual_platform == 'other'">
                <a-form-item ref="other_platform" name="other_platform" label="Nombre de la plataforma *" class="form-font mx-3">
                  <a-input v-model:value="formState.other_platform" placeholder="" style="border-radius: 30px" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24">
                <a-form-item ref="link" name="link" label="Link para el evento virtual *" class="form-font mx-3">
                  <a-input v-model:value="formState.link" placeholder="" style="border-radius: 30px" />
                </a-form-item>
              </a-col>
            </a-row>
            <div v-if="dataDOM.showFormPresential  || (dataDOM.selectSede && formState.sede_id)">
              <a-row :gutter="30">
                <a-col :md="12" :xs="12">
                  <a-form-item :disabled='dataDOM.selectSede' ref="name" name="name" label="Nombre de la sede presencial *" class="form-font mx-3">
                    <a-input :disabled='dataDOM.selectSede && formState.sede_id' v-model:value="formState.name" placeholder="" style="border-radius: 30px" />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="12" v-if="eventForm.generalInfo.type != 'benefits'">
                  <p class="form-font m-0">¿Habilitar asistentes? *</p>
                  <a-form-item ref="info_visible" name="info_visible" class="form-font pt-0 mx-3">
                    <a-radio-group v-model:value="formState.assistants">
                      <a-radio class="form-font" :value="1"> Si </a-radio>
                      <a-radio class="form-font" :value="0"> No </a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="12" :xs="24">
                  <a-form-item ref="postal_code" name="postal_code" label="C.P. *" class="form-font mx-3">
                    <a-input-search
                      :disabled='dataDOM.selectSede && formState.sede_id'
                      type="number"
                      v-model:value="formState.postal_code"
                      placeholder=""
                      style="border-radius: 30px"
                      maxlength="5"
                      v-on:keyup="getInfoByCP($event)"
                      :loading="dataDOM.showLoaderInputZipCode"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item ref="street" name="street" label="Calle *" class="form-font mx-3">
                    <a-input :disabled='dataDOM.selectSede && formState.sede_id' v-model:value="formState.street" placeholder="" style="border-radius: 30px" />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="12" :xs="24">
                  <a-form-item
                    ref="references"
                    name="references"
                    label="Referencias (entre calles) *"
                    class="form-font mx-3"
                  >
                    <a-input :disabled='dataDOM.selectSede && formState.sede_id' v-model:value="formState.references" placeholder="" style="border-radius: 30px" />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item ref="suburb" name="suburb" label="Colonia *" class="form-font mx-3">
                    <a-select
                      :disabled='dataDOM.selectSede'
                      size="large"
                      name="settlements"
                      class="sDash_fullwidth-select title-normal"
                      v-model:value="formState.suburb"
                    >
                      <a-select-option class="title-normal" value="other">Otra Colonia</a-select-option>
                      <a-select-option
                        :disabled='dataDOM.selectSede && formState.sede_id'
                        class="title-normal"
                        v-for="item in dataDOM.settlements"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24" v-if="formState.suburb == 'other'">
                  <a-form-item ref="other_suburb" name="other_suburb" label="Otra colonia *" class="form-font mx-3">
                    <a-input :disabled='dataDOM.selectSede && formState.sede_id' v-model:value="formState.other_suburb" placeholder="" style="border-radius: 30px" />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="12" :xs="24">
                  <a-form-item ref="municipality" name="municipality" label="Municipio *" class="title-normal">
                    <a-input

                      name="municipality"
                      class="sDash_fullwidth-select title-normal"
                      v-model:value="formState.municipality"
                      style="border-radius: 30px"
                      readonly
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <a-row :gutter="30" v-if="dataDOM.showGeneralForm || !dataDOM.sedes.length">
              <a-col :md="24" :xs="24">
                <button class="btn-fill-add" type='button' v-if="!dataDOM.isFormOnEdit" v-on:click="handleFormSede">
                  <span class="title-normal"> Guardar sede </span>
                </button>
                <button class="btn-fill-add" v-if="dataDOM.isFormOnEdit" v-on:click="handleFormSedeEdit">
                  <span class="title-normal"> Guardar cambios </span>
                </button>
                <button class="btn-outlined-exit" v-if="dataDOM.isFormOnEdit" v-on:click="handleFormSedeEditCancel">
                  <span class="title-normal"> Cancelar </span>
                </button>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
      <br />
      <!--FIN::FORMULARIO SEDES-->
      <!--INICIO::SEDES AGREGADAS-->
      <div v-if="dataDOM.sedes.length">
        <div v-for="(item, index) in dataDOM.sedes" :key="item">
          <a-row :gutter="30">
            <a-col :md="24" :xs="24">
              <div class="card-sedes">
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <div
                      style="float: right;background: rgb(241 248 253);border-radius: 50px;padding: 5px;width: 25px;height: 25px;"
                    >
                      <sdDropdown :action="['click']">
                        <template #overlay>
                          <button class="bt-option-card" v-on:click="editSedeRegister(index, item)">
                            <sdFeatherIcons type="edit" style="float:left;margin-right: 0.5rem;" />
                            <span class="title-normal"> Editar sede</span>
                          </button>
                          <br />
                          <button class="bt-option-card" v-on:click="removeSedeRegister(index)">
                            <sdFeatherIcons type="trash-2" style="float:left;margin-right: 0.5rem;" />
                            <span class="title-normal">Eliminar sede</span>
                          </button>
                        </template>
                        <sdFeatherIcons type="more-vertical" style="color: #3FA7E1;opacity: none !important" />
                      </sdDropdown>
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="30">
                  <a-col
                    :md="index == 'type' ? 24 : 12"
                    :xs="24"
                    v-for="(prop, index) in getCleanEmptyFieldsForm(item)"
                    :key="prop"
                  >
                    <p :class="index == 'virtual_platform' || index == 'link' ? 'saved-sedes' : ''">
                      <span class="title-bold-normal" v-if="index != 'id'"> {{ getLabelTextByInput(index) }}: </span>
                      <span class="title-normal" v-if="index != 'id'">
                        {{ index == 'type' ? getTypeResult(prop) : prop }}
                      </span>
                    </p>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
        <!--FIN::SEDES AGREGADAS-->
        <!--INICIO::BUTTON AGREGAR SEDES-->
        <a-row :gutter="30">
          <a-col :md="24" :xs="24" style="text-align: center;">
            <button class="btn-outlined-add btn-not-border" v-on:click="handleAddAnotherSede">
              <img :src="require(`@/static/img_events/SEJ_Eventos_agregar.svg`)" alt="logo" width="17" />
              <span class="title-normal ml-1"> Agregar otra sede </span>
            </button>
          </a-col>
        </a-row>
      </div>
      <!--FIN::BUTTON AGREGAR SEDES-->
      <!--INICIO::MODAL CONFIRMACION AGREGAR MAS SEDES-->
      <sdModal
        type="primary"
        :width="300"
        :visible="dataDOM.visible"
        :onCancel="handleCancel"
        class="title-bold-normal"
      >
        <a-row :gutter="25" class="mt-4" style="text-align: center;">
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <h3 class="title-normal">
              ¿Deseas agregar otra sede?
            </h3>
          </a-col>
        </a-row>
        <a-row :gutter="25" style="text-align: center; margin-top: 2rem;">
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <button class="btn-outlined-exit" v-on:click="handleNoMoreSedeForm">
              <span class="title-normal"> No </span>
            </button>
            <button class="btn-fill-add new-sede" v-on:click="handleOk">
              <span class="title-normal"> Sí </span>
            </button>
          </a-col>
        </a-row>
      </sdModal>
      <!--FIN::MODAL CONFIRMACION AGREGAR MAS SEDES-->
    </div>
  </a-row>
</template>
<script>
import { FormValidationWrap, VerticalFormStyleWrap } from '../Style';
import { reactive, ref } from 'vue';
import { getDataByCP } from '@/services/sepomex';




const SedeForm = {
  name: 'SedeForm',
  components: {
    FormValidationWrap,
    VerticalFormStyleWrap,
  },
  props: {
    sedesForm: [],
    sendForm: null,
    eventForm: Object,
    typeEvent: null,
    headquartersFromApi: []
  },
  watch: {
    sendForm: function() {
      this.submit();
    },
    typeEvent: function(newValue) {
      if (newValue == 'other') {
        this.dataDOM.sedes = [];
      }
    },
  },
  emits: ['getFormValues'],

  async setup(props, { emit }) {
    const formRef = ref();
    const getLabelsByInput = () => {
      return {
        type: {
          text: 'Tipo de sede',
        },
        virtual_platform: {
          text: 'Plataforma digital donde se llevará el evento',
        },
        other_platform: {
          text: 'Nombre de la plataforma',
        },
        name: {
          text: 'Nombre de la sede presencial',
        },
        link: {
          text: 'Link para el evento virtual',
        },
        street: {
          text: 'Calle',
        },
        references: {
          text: 'Referencias (entre calles)',
        },
        suburb: {
          text: 'Colonia',
        },
        postal_code: {
          text: 'C.P.',
        },
        municipality: {
          text: 'Municipio',
        },
        other_suburb: {
          text: 'Otra colonia',
        },
        assistants: {
          text: '¿Habilitar asistentes?',
        },
      };
    };

    const getLabelTextByInput = index => {
      const labels = getLabelsByInput();
      if(labels[index]){
        return labels[index]?.text;
      }
    return ''
    };

    const dataDOM = reactive({
      selectSede: false,
      showFormPresential: false,
      showFormVirtual: false,
      showGeneralForm: true,
      visible: false,
      /*municipalities: await getTownsFunc(),*/
      labels: getLabelsByInput(),
      layout: 'vertical',
      sedes: [],
      other_suburb: '',
      isFormOnEdit: false,
      actualIdSedeEdit: '',
      settlements: [], //colonias
      typeSedes: [
        {
          value: 'virtual',
          label: 'Virtual',
        },
        {
          value: 'face-to-face',
          label: 'Presencial',
        },
        {
          value: 'both',
          label: 'Ambas (virtual y presencial)',
        },
      ],
      timer: undefined,
      showLoaderInputZipCode: false,
    });
    const options = ref(props.headquartersFromApi);

    const handleTypeSedesSelectable = () => {
      for (let i = 0; i < dataDOM.sedes.length; i++) {
        if (dataDOM.sedes[i].type == 'virtual' || dataDOM.sedes[i].type == 'both') {
          dataDOM.typeSedes = [
            {
              value: 'face-to-face',
              label: 'Presencial',
            },
          ];
          return;
        }
      }
      dataDOM.typeSedes = [
        {
          value: 'virtual',
          label: 'Virtual',
        },
        {
          value: 'face-to-face',
          label: 'Presencial',
        },
        {
          value: 'both',
          label: 'Ambas (virtual y presencial)',
        },
      ];
    };

    const formState = reactive({
      sede_id: '',
      type: '',
      virtual_platform: '',
      other_platform: '',
      link: '',
      name: '',
      street: '',
      references: '',
      suburb: '',
      postal_code: '',
      municipality: '',
      assistants: 0,
      other_suburb: '',
    });

    const rules = {
      sede: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'onchange',
          type: 'string',
        },
      ],
      type: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      virtual_platform: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      link: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
        {
          pattern: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g,
          message: 'Link con formato inválido',
          trigger: 'blur',
        },
      ],
      other_platform: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        }
      ],
      name: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      street: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      references: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      suburb: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      postal_code: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
        {
          min: 5,
          max: 5,
          message: 'Código postal no válido, deben ser 5 caracteres exactos',
          trigger: 'blur',
        },
      ],
      municipality: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
      assistants: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'number',
        },
      ],
      other_suburb: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'string',
        },
      ],
    };

    const handleChangeSede = (event, editingSedeFromApi=false) => {
      switch (event) {
        case 'face-to-face':
          dataDOM.selectSede = Boolean(!dataDOM.isFormOnEdit || (dataDOM.isFormOnEdit && editingSedeFromApi));
          dataDOM.showFormPresential = dataDOM.showFormPresential || dataDOM.isFormOnEdit || editingSedeFromApi || Boolean(  formState.sede_id );
          dataDOM.showFormVirtual = false;
          break;
        case 'virtual':
          dataDOM.showFormPresential = false;
          dataDOM.showFormVirtual = true;
          dataDOM.selectSede = false
          break;
        case 'both':
          dataDOM.selectSede = Boolean(!dataDOM.isFormOnEdit || (dataDOM.isFormOnEdit && editingSedeFromApi));
          dataDOM.showFormPresential = dataDOM.showFormPresential || dataDOM.isFormOnEdit || editingSedeFromApi || Boolean(  formState.sede_id );
          dataDOM.showFormVirtual = true;

          break;
        default:
          break;
      }
    };
    const handleChangeSedeSelect = event =>{
      const sede = props.headquartersFromApi.find(sede=>sede.id==event)
      formState.name= sede.edificio
      formState.street= sede.calle
      formState.references= ''
      formState.suburb= sede.colonia
      formState.postal_code= ''
      formState.municipality= sede.municipio
      //formState.assistants= 0
      formState.other_suburb= ''
    }

    const getCleanEmptyFieldsForm = form => {
      const sede = JSON.parse(JSON.stringify(form));
      switch (sede.type) {
        case 'face-to-face':
          sede.assistants = sede.assistants == 1 ? 'Sí' : 'No';
          if (sede.other_suburb == '') {
            delete sede['other_suburb'];
          }
          if (sede.suburb == 'other') {
            delete sede['suburb'];
          }
          delete sede['other_platform'];
          delete sede['virtual_platform'];
          delete sede['link'];
          delete sede['sede_id'];
          break;
        case 'virtual':
          if (sede.other_platform == '') {
            delete sede['other_platform'];
          }
          if (sede.virtual_platform == 'other') {
            delete sede['virtual_platform'];
          }
          delete sede['assistants'];
          delete sede['municipality'];
          delete sede['name'];
          delete sede['postal_code'];
          delete sede['references'];
          delete sede['street'];
          delete sede['suburb'];
          delete sede['other_suburb'];
          delete sede['sede_id'];
          break;
        case 'both':
          if (sede.other_platform == '') {
            delete sede['other_platform'];
          }
          if (sede.virtual_platform == 'other') {
            delete sede['virtual_platform'];
          }
          sede.assistants = sede.assistants == 1 ? 'Sí' : 'No';
          if (sede.other_suburb == '') {
            delete sede['other_suburb'];
          }
          if (sede.suburb == 'other') {
            delete sede['suburb'];
          }
          delete sede['sede_id'];
          break;
      }
      return sede;
    };

    const getTypeResult = prop => {
      let result = '';
      switch (prop) {
        case 'face-to-face':
          result = 'Presencial';
          break;
        case 'virtual':
          result = 'Virtual';
          break;
        case 'both':
          result = 'Ambas (virtual y presencial)';
          break;
        default:
          break;
      }
      return result;
    };

    const handleNoMoreSedeForm = () => {
      let actualSede = JSON.parse(JSON.stringify(formState));
      if(!actualSede.id){
        delete actualSede['id'];
      }
      dataDOM.sedes.push(actualSede);
      props.sedesForm.push(actualSede);

      dataDOM.visible = false;
      dataDOM.showFormPresential = false;
      dataDOM.showFormVirtual = false;
      dataDOM.selectSede = false;
      resetForm();
      dataDOM.showGeneralForm = false;
      handleTypeSedesSelectable();
    };

    const handleOk = () => {
      let actualSede = JSON.parse(JSON.stringify(formState));
      props.sedesForm.push(actualSede);
      dataDOM.sedes.push(actualSede);

      resetForm();

      dataDOM.visible = false;
      dataDOM.showFormPresential = false;
      dataDOM.showFormVirtual = false;

      handleTypeSedesSelectable();
    };

    const handleCancel = () => {
      dataDOM.visible = false;
    };

    const handleFormSede = () => {

      if(dataDOM.selectSede && formState.sede_id){
        if (!formState.type) return;
        
        if (formState.type === "both" || formState.type === "virtual") {
          formRef.value
            .validateFields(["link", "virtual_platform", "other_platform"])
            .then(() => {
              dataDOM.visible = true;
            })
            .catch(() => {
              dataDOM.visible = false;
        });
          return;
        }
        formRef.value.clearValidate()
        dataDOM.visible = true;
        return;
      }
      formRef.value
        .validate()
        .then(() => {
          dataDOM.visible = true;
        })
        .catch(() => {
          dataDOM.visible = false;
        });
    };

    const handleAddAnotherSede = () => {
      resetForm();
      dataDOM.showGeneralForm = true;
      dataDOM.selectSede = false
    };
    const handleAddNewSede = () => {
      const prevState = formState.type
      resetForm();
      formState.type=prevState
      dataDOM.selectSede = false
      dataDOM.showFormPresential = true;

    };

    const removeSedeRegister = indexForm => {
      dataDOM.sedes.splice(indexForm, 1);
      props.sedesForm.splice(indexForm, 1);
      handleTypeSedesSelectable();
    };

    const editSedeRegister = async (indexForm, sede) => {
      resetForm();
      dataDOM.typeSedes = [
        {
          value: 'virtual',
          label: 'Virtual',
        },
        {
          value: 'face-to-face',
          label: 'Presencial',
        },
        {
          value: 'both',
          label: 'Ambas (virtual y presencial)',
        },
      ];
      if(!sede.sede_id){
        await getZipCodeRequest({ zip_code: sede.postal_code });
      }

      dataDOM.actualIdSedeEdit = indexForm;
      dataDOM.isFormOnEdit = true;
      dataDOM.showGeneralForm = true;
      handleChangeSede(sede.type, sede.sede_id);
      formState.type = sede.type;
      formState.virtual_platform = sede.virtual_platform;
      formState.other_platform = sede.other_platform;
      formState.link = sede.link;
      formState.name = sede.name;
      formState.street = sede.street;
      formState.references = sede.references;
      formState.suburb = sede.suburb;
      formState.postal_code = sede.postal_code;
      formState.municipality = sede.municipality;
      formState.other_suburb = sede.other_suburb;
      formState.assistants = sede.assistants;
      formState.sede_id = sede.sede_id;
    };

    const handleFormSedeEditCancel = () => {
      resetForm();
      dataDOM.actualIdSedeEdit = '';
      dataDOM.isFormOnEdit = false;
      dataDOM.showFormPresential = false;
      dataDOM.showFormVirtual = false;
      dataDOM.showGeneralForm = false;
      dataDOM.selectSede = false;
    };

    const handleFormSedeEdit = () => {
      let actualSede = handleClearSedeFormEditByType(JSON.parse(JSON.stringify(formState)));

      removeSedeRegister(dataDOM.actualIdSedeEdit);
      //dataDOM.sedes[dataDOM.actualIdSedeEdit] = actualSede;
      dataDOM.sedes.push(actualSede);
      props.sedesForm.push(actualSede);

      dataDOM.actualIdSedeEdit = '';
      dataDOM.isFormOnEdit = false;
      dataDOM.showFormPresential = false;
      dataDOM.showFormVirtual = false;
      resetForm();
      dataDOM.showGeneralForm = false;
      dataDOM.selectSede = false;
    };

    const handleClearSedeFormEditByType = sede => {
      if (sede.type == 'face-to-face') {
        return {
          sede_id: sede.sede_id,
          type: sede.type,
          virtual_platform: '',
          other_platform: '',
          link: '',
          name: sede.name,
          street: sede.street,
          references: sede.references,
          suburb: sede.suburb,
          postal_code: sede.postal_code,
          municipality: sede.municipality,
          other_suburb: sede.other_suburb,
          assistants: sede.assistants,
        };
      } else if (sede.type == 'virtual') {
        return {
          sede_id: sede.sede_id,
          type: sede.type,
          virtual_platform: sede.virtual_platform,
          other_platform: sede.other_platform,
          link: sede.link,
          name: '',
          street: '',
          references: '',
          suburb: '',
          postal_code: '',
          municipality: '',
          other_suburb: '',
          assistants: 1,
        };
      } else {
        return {
          sede_id: sede.sede_id,
          type: sede.type,
          virtual_platform: sede.virtual_platform,
          other_platform: sede.other_platform,
          link: sede.link,
          name: sede.name,
          street: sede.street,
          references: sede.references,
          suburb: sede.suburb,
          postal_code: sede.postal_code,
          municipality: sede.municipality,
          other_suburb: sede.other_suburb,
          assistants: sede.assistants,
        };
      }
    };

    const resetForm = () => {
      formRef.value.resetFields();
      formState.sede_id = '';
      formState.type = '';
      formState.virtual_platform = '';
      formState.other_platform = '';
      formState.link = '';
      formState.name = '';
      formState.street = '';
      formState.references = '';
      formState.suburb = '';
      formState.postal_code = '';
      formState.municipality = '';
      formState.other_suburb = '';
      formState.assistants = 1;
      dataDOM.settlements = [];
      handleTypeSedesSelectable();
    };

    const submit = () => {
      if (props.eventForm.generalInfo.type != 'other') {
        emit('getFormValues', {
          is_ok: dataDOM.sedes.length ? true : false,
          typeForm: 'sedes',
          messageRequired: 'Sedes es requerido',
        });
      } else {
        dataDOM.sedes = [];
        emit('getFormValues', { is_ok: false, typeForm: 'sedes' });
      }
    };

    const getInfoByCP = async input => {
      const data = {
        zip_code: input.target.value,
      };

      if (dataDOM.timer) {
        clearTimeout(dataDOM.timer);
      }

      dataDOM.showLoaderInputZipCode = true;

      dataDOM.timer = setTimeout(async () => {
        //SendRequest
        if (data.zip_code == '') {
          dataDOM.showLoaderInputZipCode = true;
          return;
        }
        await getZipCodeRequest(data);
      }, 700);
    };

    const getZipCodeRequest = async data => {
      await getDataByCP(data)
        .then(response => {
          if (response.success) {
            formState.municipality = response.data.municipality;
            dataDOM.settlements = response.data.settlements;
            dataDOM.showLoaderInputZipCode = false;
          }
        })
        .catch(() => {
          dataDOM.showLoaderInputZipCode = true;
        });
    };

    return {
      formRef,
      formState,
      rules,
      handleChangeSede,
      dataDOM,
      handleFormSede,
      handleOk,
      handleCancel,
      resetForm,
      getLabelTextByInput,
      getCleanEmptyFieldsForm,
      handleNoMoreSedeForm,
      handleAddAnotherSede,
      removeSedeRegister,
      editSedeRegister,
      handleFormSedeEditCancel,
      handleFormSedeEdit,
      submit,
      getTypeResult,
      getInfoByCP,
      options,
      handleChangeSedeSelect,
      handleAddNewSede
    };
  },
};

export default SedeForm;
</script>
<style scoped lang="sass">
.card
  background-color: #fff !important
  padding: 1rem
  border-top: 0.5px solid #bdbdbd

.saved-sedes
  margin-bottom: 1.5rem


.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
.btn-fill-add
  border: 1px solid #e70e4c
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #e70e4c
  border-radius: 24px
  margin: 0
.new-sede
  background: #3FA7E1
  border: 1px solid #3FA7E1
  margin: 0 1rem 0 0

button:hover
  cursor: pointer
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin: 0 1rem
.card-sedes
  background-color: #ffff
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
  padding: 1.5rem
  margin: 1rem
.btn-outlined-add
  border: 1px solid #e70e4c
  color: #e70e4c
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 7px
.bt-option-card
  width: 100%
  color: #212529
  background: none
  border: none
  padding-left: 15px !important
  padding: 5px
  font-size: 12px
  margin-bottom: 0.5rem
.bt-option-card:hover
  cursor: pointer
  background: #f1efef
.btn-not-border
  border: none !important
.sede-text
  font-size: 14px
</style>
